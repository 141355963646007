import React from 'react';
import LogoImage from '../../../assets/icons/logo.svg';
import {useQuery} from 'react-apollo';
import {GET_STORE_CONFIG} from '../../../commonQueries/getBackendConfig';
import readConfig from "../../../utils/readClientConfiguration";
import productionConfiguration from "../../../../config/default.json";
const Logo = (props) => {

  const base_media_url = readConfig(productionConfiguration, 'base_media_url');
  let logoUrl = LogoImage;
  const {data: backendConfig, loading: backendConfigLoading} = useQuery(GET_STORE_CONFIG);

  if (!backendConfigLoading) {
    if (backendConfig.storeConfig.header_logo_src) {
      logoUrl = backendConfig.storeConfig.header_logo_src;
      logoUrl = !logoUrl.startsWith('http') ? `${base_media_url}logo/${logoUrl}` : LogoImage;
    }
  }
  if(!backendConfig){
    return null;
  }
  return (
    <img src={logoUrl} {...props}/>
  );
};

export default Logo;