import { useState } from 'react';

export const useAccountPopup = () => {
  const [accountPopupVisible, setShow] = useState(false);
  const showAccPopup = () => setShow(true);
  const hideAccPopup = () => setShow(false);
  const toggleAccountPopup = () => setShow(prev => !prev);

  return { accountPopupVisible, showAccPopup, hideAccPopup, toggleAccountPopup };
};
