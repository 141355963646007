/* eslint-disable camelcase */
import { I18nContext, T } from '@deity-io/falcon-i18n';
import clsx from 'clsx';
import React, { useContext } from 'react';
import formatLocalePrice from '../../../utils/formatLocalePrice';
import productionConfiguration from '../../../../config/default.json';
import readConfig from '../../../utils/readClientConfiguration';
import ProductPromo from '../ProductPromo/ProductPromo';
import Prices from '../../BaseCurrencyPrices/Prices';
import { useProduct } from '../../../context/ProductContext';
import { hasValidGroupedClearance, hasValidGroupedPromo, isValidPromoOrClearance, hasValidSimpleClearance } from '../../../utils/promoAndClearanceValidity';
import './ProductPrices.scss';
import Price from "../../Price/Price";

const baseClassName = 'product-price';
const countryCode = readConfig(productionConfiguration, 'countryCode');

// TODO : Fetch labels from magento

const ProductPrices = ({
  prices,
  isProductPage = false,
  isCms,
  isSearch,
  isDropdown,
  isFirstCalled,
  isCategory,
  advancedInventory,
  specialPrice,
  price_last_30,
  inHeader,
}) => {
  const { t } = useContext(I18nContext);
  const product = useProduct();
  
  const { price_type, amount, old_amount, label, percentage, txt_color, bg_color, promotion_end, promotion_start } =
    (prices && prices[0]) || {};

  const isPromo = price_type === 'simple_promo';
  const isLoyalty = price_type.includes('loyalty');
  const isPromoHigher30Days = amount >= price_last_30;

  const formatedOldAmount = formatLocalePrice((isPromo && specialPrice) || old_amount);

  let enableQtyIncrements = '0';
  let textNextToPrice = '';
  let slash = '';
  if (advancedInventory && advancedInventory.unit && advancedInventory.unit.charAt(0) !== '/') {
    slash = '/';
  }

  if (typeof advancedInventory !== 'undefined' && advancedInventory.hasOwnProperty('enable_qty_increments')) {
    enableQtyIncrements = advancedInventory.enable_qty_increments;
    textNextToPrice = slash + advancedInventory.unit;
  }
  switch (price_type) {
    case 'simple_regular': {
      return (
        <div
          className={clsx(
            `${baseClassName}__price-wrap`,
            isProductPage && `${baseClassName}__price-wrap_product`,
            isSearch && `${baseClassName}__price-wrap_search`,
          )}
        >
          <div
            className={clsx(
              `${baseClassName}__price price`,
              isDropdown && `${baseClassName}__dropdown`,
              (isCategory || isSearch) && `${baseClassName}__price__category`,
            )}
          >
            <Prices
              amount={amount}
              isCategory={isCategory}
              isSearch={isSearch}
              isDropdown={isDropdown}
              isProductPage={isProductPage}
              isCms={isCms}
              textNextToPrice={enableQtyIncrements === '1' && textNextToPrice}
            />
          </div>
        </div>
      );
    }
    case 'simple_clearance': {
      return (
        <>
          {!isLoyalty && !isProductPage && (
            <div className={clsx(`${baseClassName}__price price`, isDropdown && `${baseClassName}__dropdown`)}>
              <Prices
                amount={amount}
                isCategory={isCategory}
                isSearch={isSearch}
                isDropdown={isDropdown}
                isProductPage={isProductPage}
                isCms={isCms}
                textNextToPrice={enableQtyIncrements === '1' && textNextToPrice}
              />
            </div>
          )}
          <div
            style={{
              justifyContent:  'space-between'
            }}
            className={clsx(
              `${baseClassName}__price-wrap`,
              isProductPage && `${baseClassName}__price-wrap_product`,
              isSearch && `${baseClassName}__price-wrap_search`,
              isDropdown && `${baseClassName}__price-wrap_search`,
            )}
          >
            {!isProductPage && countryCode !== 'hr-HR' && (
              <div
                className={clsx(
                  countryCode !== 'hr-HR' && `${baseClassName}__old-price_related_dash_through price`,
                  isProductPage && `${baseClassName}__old-price_product`,
                  isSearch && `${baseClassName}__old-price_search`,
                  isDropdown && `${baseClassName}__dropdown-clearance`,
                )}
              >
                {formatedOldAmount}
                {enableQtyIncrements === '1' && textNextToPrice}
              </div>
            )}

            {hasValidSimpleClearance(product) && (
              <ProductPromo
                type="promo"
                promoText={<T id="productItem.simpleClearance" />}
                promoPercentage={percentage}
                isProductPage={isProductPage}
                isSearch={isSearch}
                promotionEnd={promotion_end}
                promotionStart={promotion_start}
                price_last_30={price_last_30}
                amount={amount}
                isPriceLabel
                inHeader={inHeader}
                isCms={isCms}
                priceType={price_type}
                isCategory={isCategory}
                isDropdown={isDropdown}
              />
            )}
          </div>
        </>
      );
    }
    case 'simple_loyalty': {
      return (
        <>
          <div style={{
            display: 'flex',
            justifyContent:  'space-between'
          }} className={clsx((isSearch || isDropdown) && 'loyalty-div__search')}>
            {/*{countryCode !== 'hr-HR' && (*/}
            {/*  <div className="loyalty-div">*/}
            {/*    {!isProductPage && (*/}
            {/*      <div*/}
            {/*        className={clsx(*/}
            {/*          `${baseClassName}__price price`,*/}
            {/*          isLoyalty && `${baseClassName}__loyalty-price`,*/}
            {/*          isDropdown && `${baseClassName}__loyalty-price_dropdown`,*/}
            {/*        )}*/}
            {/*      >*/}
            {/*        <Prices*/}
            {/*          amount={amount}*/}
            {/*          isCategory={isCategory}*/}
            {/*          isSearch={isSearch}*/}
            {/*          isDropdown={isDropdown}*/}
            {/*          isProductPage={isProductPage}*/}
            {/*          isCms={isCms}*/}
            {/*          textNextToPrice={enableQtyIncrements === '1' && textNextToPrice}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*)}*/}
            <div
              className={clsx(
                `${baseClassName}__price-wrap`,
                isProductPage && `${baseClassName}__price-wrap_product`,
                isSearch && `${baseClassName}__price-wrap_search`,
              )}
            >
              <ProductPromo
                type="club"
                promoText={<T id="productItem.simpleLoyalty" />}
                promoPercentage={percentage}
                isProductPage={isProductPage}
                isSearch={isSearch}
                isDropdown={isDropdown}
                promotionEnd={promotion_end}
                promotionStart={promotion_start}
                price_last_30={price_last_30}
                amount={amount}
                isPriceLabel
                inHeader={inHeader}
                isCms={isCms}
              />
            </div>
          </div>
        </>
      );
    }
    case 'simple_promo': {
      return (
        <>
          <div
            className={clsx(
              `${baseClassName}__promo-wrap`,
              countryCode === 'hr-HR' && '__hr_flex_row',
            isCms && '__cms_flex_column',
            )}
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {!isProductPage && (
              <div
                className={clsx(
                  `${baseClassName}__price-wrap`,
                  `${baseClassName}__promo-price price`,
                  txt_color && label !== 'action' && `${baseClassName}__promo-price-custom price-custom`,
                  isDropdown && `${baseClassName}__promo-price-custom-dropdown`,
                  (isCategory || isCms) && `${baseClassName}__price__category`,
                )}
              >
                <Prices
                  amount={amount}
                  isCategory={isCategory}
                  isSearch={isSearch}
                  isDropdown={isDropdown}
                  isProductPage={isProductPage}
                  isCms={isCms}
                  textNextToPrice={enableQtyIncrements === '1' && textNextToPrice}
                />
              </div>
            )}
            {!isDropdown && isValidPromoOrClearance(amount, price_last_30) && (
              <div
                className={clsx(
                  `${baseClassName}__price-wrap`,
                  isProductPage && `${baseClassName}__price-wrap_product`,
                  isSearch && `${baseClassName}__price-wrap_search-simple`,
                )}
              >
                <ProductPromo
                  type="primary"
                  promoText={t(label)}
                  promoPercentage={percentage}
                  isProductPage={isProductPage}
                  isSearch={isSearch}
                  txt_color={txt_color}
                  bg_color={bg_color}
                  promotionEnd={promotion_end}
                  promotionStart={promotion_start}
                  isDropdown={isDropdown}
                  price_last_30={price_last_30}
                  amount={amount}
                  isPriceLabel
                  inHeader={inHeader}
                  isCms={isCms}
                />
              </div>
            )}
          </div>
          {(!isFirstCalled || prices[0].label !== 'STiCKER') && !isProductPage && !isDropdown && (
            <div
              style={{ display: 'block' }}
              className={clsx(
                countryCode !== 'hr-HR' && `${baseClassName}__old-price_related_dash_through price`,
                isProductPage && `${baseClassName}__old-price_product`,
                isSearch && `${baseClassName}__old-price_search`,
                isDropdown && `${baseClassName}__old-price-dropdown price-dropdown`,
              )}
            >
              {countryCode !== 'ahr-HR' && (
                <>
                  {formatedOldAmount}
                  {enableQtyIncrements === '1' && textNextToPrice}
                </>
              )}
            </div>
          )}
          {isDropdown  && (
            <div style={{ display: 'block' }} className="dropdown">
              {formatedOldAmount}
            </div>
          )}
          {isDropdown && isValidPromoOrClearance(amount, price_last_30) && (
            <div
              className={clsx(
                `${baseClassName}__price-wrap`,
                isProductPage && `${baseClassName}__price-wrap_product`,
                isSearch && `${baseClassName}__price-wrap_search-simple`,
              )}
            >
              <ProductPromo
                type="primary"
                promoText={t(label)}
                promoPercentage={percentage}
                isProductPage={isProductPage}
                isSearch={isSearch}
                txt_color={txt_color}
                bg_color={bg_color}
                promotionEnd={promotion_end}
                promotionStart={promotion_start}
                isDropdown={isDropdown}
                price_last_30={price_last_30}
                amount={amount}
                isPriceLabel
                inHeader={inHeader}
                isCms={isCms}
              />
            </div>
          )}
        </>
      );
    }
    case 'grouped_item': {
      return (
        <>
          <div className={`${baseClassName}__price price`}>
            <T id="product.pricePerElements" />
          </div>
        </>
      );
    }
    case 'grouped_dimensions': {
      return (
        <>
          <div className={`${baseClassName}__price price`}>
            <T id="product.pricePerDimensions" />
          </div>
          <div className="promo-margin">
            {label && (
              <ProductPromo
                type="club"
                promoText={label}
                isProductPage={isProductPage}
                inHeader={inHeader}
                isCms={isCms}
              />
            )}
          </div>
        </>
      );
    }
    case 'grouped_already': {
      return (
        <div className={clsx(`${baseClassName}__price-wrap`, isProductPage && `${baseClassName}__price-wrap_product`)}>
          <div className={`${baseClassName}__price price`}>
            <T id="product.priceAlreadyFromLabel" />:{' '}
            <Prices
              amount={amount}
              isCategory={isCategory}
              isDropdown={isDropdown}
              isProductPage={isProductPage}
              isCms={isCms}
            />
          </div>
        </div>
      );
    }
    case 'grouped_promo': {
      if (!hasValidGroupedPromo(product)) {
        return null;
      }

      return (
        <div
          className={clsx(
          `${baseClassName}__price-wrap`,
            (isCategory || isSearch) && `${baseClassName}__grouped-label`,
            isProductPage && `${baseClassName}__price-wrap_product`,
          )}
        >
          <div className={`${baseClassName}__price price`}>
            <div
              className={clsx(
                `${baseClassName}__price-wrap`,
                isProductPage && `${baseClassName}__price-wrap_product`,
                isSearch && `${baseClassName}__price-wrap_search-simple`,
              )}
            >
              <ProductPromo
                type="primary"
                isGrouped
                promoText={<T id="productItem.groupedPromo" />}
                inHeader={inHeader}
                isCms={isCms}
              />
            </div>
          </div>
        </div>
      );
    }
    case 'grouped_clearance': {
      if (!hasValidGroupedClearance(product)) {
        return null;
      }

      return (
        <div
          className={clsx(
            `${baseClassName}__price-wrap`,
            (isCategory || isSearch) && `${baseClassName}__grouped-label`,
            isProductPage && `${baseClassName}__price-wrap_product`,
          )}
        >
          <div className={`${baseClassName}__price price`}>
            <div
              className={clsx(
                `${baseClassName}__price-wrap`,
                isProductPage && `${baseClassName}__price-wrap_product`,
                isSearch && `${baseClassName}__price-wrap_search`,
              )}
            >
              <ProductPromo
                type="promo"
                isGrouped
                promoText={<T id="productItem.groupedClearance" />}
                inHeader={inHeader}
                isCms={isCms}
              />
            </div>
          </div>
        </div>
      );
    }
    case 'grouped_loyalty': {
      return (
        <div
          className={clsx(
            `${baseClassName}__price-wrap`,
            (isCategory || isSearch) && `${baseClassName}__grouped-label`,
            isProductPage && `${baseClassName}__price-wrap_product`,
          )}
        >
          <div className={`${baseClassName}__price price`}>
            <div
              className={clsx(
                `${baseClassName}__price-wrap`,
                isProductPage && `${baseClassName}__price-wrap_product`,
                isSearch && `${baseClassName}__price-wrap_search`,
              )}
            >
              <ProductPromo
                type="club"
                isGrouped
                promoText={<T id="productItem.groupedLoyalty" />}
                inHeader={inHeader}
                isCms={isCms}
              />
            </div>
          </div>
        </div>
      );
    }
    default:
      break;
  }
  return null;
};

export default ProductPrices;
