/* eslint-disable camelcase */
import React, { useState, useCallback, useRef, useEffect, memo } from 'react';
import { useForm } from 'react-hook-form';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { T, I18n } from '@deity-io/falcon-i18n';
import UrlParamHandler from '../../../utils/urlParamHandler';
import { debounce } from '../../../utils/debounce';
import './Search.scss';
import ProductPrices from '../../UI/ProductPrices/ProductPrices';
import { ProductProvider } from '../../../context/ProductContext';
import PriceBlock from '../../Product/PriceBlock/PriceBlock';

const GET_PRODUCT = gql`
  query productsSearch($search: String, $pageSize: Int) {
    productsSearch(search: $search, pageSize: $pageSize) {
      __typename
      total_count
      items {
        name
        sku
        canonical_url
        special_price
        advanced_product_inventory {
          __typename
          is_qty_decimal
          use_config_enable_qty_inc
          enable_qty_increments
          use_config_qty_increments
          qty_increments
          use_config_min_sale_qty
          min_sale_qty
          unit
        }
        thumbnail {
          label
          url
        }
        image {
          label
          url
        }
        gallery {
          disabled
          disabled_default
          file
          id
          label
          label_default
          media_type
          medium_image_url
          position
          position_default
          row_id
          small_image_url
          url
          value_id
        }
        guarantees {
          guarantee_description
          guarantee_img_url
        }
        price_last_30
        prices {
          amount
          unit
          bg_color
          currency_symbol
          label
          description
          old_amount
          percentage
          price_type
          promotion_start
          promotion_end
          txt_color
        }
        ... on GroupedProduct {
          items {
            product {
              gallery {
                disabled
                disabled_default
                file
                id
                label
                label_default
                large_image_url
                media_type
                medium_image_url
                position
                position_default
                row_id
                small_image_url
                url
                value_id
                is_hover
                is_thumbnail
              }
              price_last_30
              prices {
                price_type
                amount
                bg_color
                currency_symbol
                label
                unit
                old_amount
                percentage
                price_type
                promotion_start
                promotion_end
                txt_color
                      description
              }
            }
          }
        }
      }
    }
  }
`;

const Search = ({ hideSearch }) => {
  const { handleSubmit } = useForm();
  const [search, setSearch] = useState(UrlParamHandler(useLocation().search).queryParams.q || '');
  const [isClicked, setClicked] = useState(false);
  const count = useRef(-1);
  const setIsClicked = React.useCallback(() => setClicked(true), []);
  const history = useHistory();

  const [fetchSearch, { data, loading }] = useLazyQuery(GET_PRODUCT);
  const delayedFetch = useCallback(
    searchTerm => debounce(() => fetchSearch({ variables: { search: searchTerm, pageSize: 5 } }), 500),
    [],
  );

  const onChange = e => {
    e.persist();
    setClicked(false);
    setSearch(e.target.value);

    if (e.target.value.length <= 1) {
      return;
    }
    delayedFetch(e.target.value);
  };

  const selectSuggestion = e => {
    if (e.key === 'ArrowDown') {
      count.current++;
      if (count.current === data.products.items.length) {
        count.current = 0;
      }
      setSearch(data.products.items[count.current].name);
      hideSearch();
    }
    if (e.key === 'ArrowUp') {
      count.current--;
      if (count.current === -1) {
        count.current = data.products.items.length - 1;
      }
      setSearch(data.products.items[count.current].name);
      hideSearch();
    }
  };

  const onSubmit = () => {
    if (search.length) {
      history.push({ pathname: '/catalogsearch/result', search: `?q=${search}` });
      hideSearch();
    }
  };

  useEffect(() => {
    document.addEventListener('click', setIsClicked);
    return () => {
      document.removeEventListener('click', setIsClicked);
    };
  }, []);

  const getThumbnail = (gallery, thumbnail) => {
    const galleryThumbnail = gallery.find(x => x.position === 1);
    if (galleryThumbnail) {
      return galleryThumbnail.small_image_url;
    }
    return thumbnail.url;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="search">
      <div className="search__field">
        <button title="Traži" type="submit" className="search__btn search_icon" />

        <I18n>
          {t => (
            <input
              autoComplete="off"
              type="text"
              className="search__input"
              name="search"
              value={search}
              onChange={onChange}
              onKeyUp={selectSuggestion}
              // TODO: translations
              placeholder={t('header.searchPlaceholder')}
            />
          )}
        </I18n>
        <button title="Traži" type="submit" className="search__btn search__btn_search" onClick={onSubmit}>
          <T id="header.searchButton" />
        </button>
      </div>

      <div
        className="search__autocomplete search-autocomplete"
        id="search-autocomplete"
        style={{ display: search.length > 1 && !loading && !isClicked ? 'block' : 'none' }}
      >
        <div className="smile-elasticsuite-autocomplete-result">
          <dl role="listbox" className="autocomplete-list">
            <a className="no-display" style={{ display: search.toLowerCase() == 'dyson' && !loading && !isClicked ? 'block' : 'none' }} href="/dyson-demozone-new"><dd role="option" id="qs-option-0"><div className="product-image-box"><img width="45px" height="auto" src="https://s3.emmezeta.hr/media/thumbs/wysiwyg/Dyson/thumbnail_dyson_brand_store.png"/></div><div><div><div className="product-primary"><div className="product-name">DYSON BRAND STORE</div></div></div><div className="clear"></div></div></dd></a>
             {data &&
              data.productsSearch &&
              data.productsSearch.items.map(
                (product, index) => {
                  const { canonical_url, sku, name, prices, thumbnail, gallery, special_price, advanced_product_inventory } = product;
                  return (
                    <ProductProvider value={product} key={sku}>
                      <Link to={`/${canonical_url}`} key={sku}>
                        <dd role="option" id={`qs-option-${index}`}>
                          <div className="product-image-box">
                            <img width="45px" height="auto" src={getThumbnail(gallery, thumbnail)} alt={thumbnail.label} />
                          </div>
                          <div className="product-shop product-item">
                            <div className="f-fix">
                              <div className="product-primary">
                                <div className="product-name">{name}</div>
                              </div>
                              <div className="product-secondary">
                                <div className="price-box">
                                  <span className="price">
                                   <PriceBlock product={product} hideLast30={true} hideLabels={false}/>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="clear" />
                          </div>
                        </dd>
                      </Link>
                    </ProductProvider>
                  );
                })}
            {data && data.productsSearch && data.productsSearch.items.length === 0 ? (
              <T id="searchResult.noResults" />
            ) : null}
          </dl>
        </div>
      </div>
    </form>
  );
};

export default memo(Search);
