/* eslint-disable jsx-a11y/no-static-element-interactions */
import { T } from '@deity-io/falcon-i18n';
import loadable from '@loadable/component';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import productionConfiguration from '../../../config/default.json';
import HeaderLupeImage from '../../assets/icons/header-icon-loupe.svg';
import HeaderLocationsImage from '../../assets/icons/header-icon-pin-stores.svg';
import { useAccountPopup } from '../../hooks/useAccountPopup';
import { useMobileSearch } from '../../hooks/useMobileSearch';
import readConfig from '../../utils/readClientConfiguration';
import './Header.scss';
import Search from './Search/Search';
import Logo from './Logo/Logo'

const MenuNavbar = loadable(() => import('./MenuNavbar/MenuNavbar'));
const HeaderMyAccount = loadable(() => import('./HeaderMyAccount/HeaderMyAccount'));
const HeaderMiniCart = loadable(() => import('./HeaderMiniCart/HeaderMiniCart'));

const country = readConfig(productionConfiguration, 'countryCode');
const isRs = country.includes('RS');

const Header = () => {
  const { hideAccPopup } = useAccountPopup();
  const { showSearch, hideSearch, toggleSearch } = useMobileSearch();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  const showSearchInlineCss = {};
  if (showSearch) {
    showSearchInlineCss.display = 'flex';
  }

  return (
    <header className="header">
      <div className="header__wrapper">
        <div className="header__logo">
          <Link to="/">
            <Logo className="header__logo-pic"  alt="logo" />
          </Link>
        </div>

        <div className="header__search" style={showSearchInlineCss}>
          <Search hideSearch={hideSearch} />
        </div>

        <div className="header__menu">
          <div
            className="header__menu-item header__menu-item_menu"
            onClick={() => setIsMenuExpanded(!isMenuExpanded)}
          />

          <div
            className="header__menu-item header__menu-item_search"
            onClick={() => {
              toggleSearch();
              hideAccPopup();
            }}
          >
            <img src={HeaderLupeImage} alt="search" />
          </div>

          <Link
            to={`/kontakt-i-usluge/lokacije-${isRs ? 'centara' : 'trgovina'}`}
            className="header__menu-item header__menu-item_locations"
          >
            <img className="header__locations-pic" src={HeaderLocationsImage} alt="locations" />
            <span className="header__locations-text">
              <T id="header.contacts" />
            </span>
          </Link>

          <HeaderMyAccount />

          <HeaderMiniCart />
        </div>
      </div>

      <MenuNavbar isMenuExpanded={isMenuExpanded} setIsMenuExpanded={setIsMenuExpanded} />
    </header>
  );
};

export default React.memo(Header);
