/**
 * Figure out the the type of the filter attribute (price range, match...)
 * @param field
 * @param data
 * @returns {string}
 */
function getType(field, data) {
    if(field === "attribute_set_id"){
        return ""
    }
    // Get products
    let products = data.queryType.fields.find(({name}) => name === "products");

    // Extract the filter
    let filter = products.args.find(({name}) => name === "filter");

    // Determine the type
    const { type } = filter.type.inputFields.find(({name}) => name === field) || {};

    // So that it doesn't yell
    if(!type){
        return "";
    }
    // Get the name
    return type.name;
}

export default function (searchParams, schema){
    const parsedParams = new URLSearchParams(searchParams)
    //Hardcoded exclusion lists for attributes
    const queryParam = ['q'];
    const pageParam = ['p', 'page-size', 'product_list_dir', 'product_list_order'];

    //Store params here
    let queryParams = {};
    let pageParams = {};
    let attributeParams = [];
    for(const [key, value] of parsedParams.entries()){
        //If it's in the query, then it's going in the query
        if(queryParam.includes(key)){
            queryParams[key] = value;
            continue;
        }

        if(pageParam.includes(key)){
            pageParams[key] = value;
            continue;
        }

        // In case we don't have the schema, just resolve the ones that we can
        if(!schema){
            continue
        }

        // Only consider it an attribute if GQL can interpret it as one
        const attributeType = getType(key, schema);
        // If we don't specify the schema
        if(attributeType || !schema) {
            attributeParams.push({key, value, inputType: getType(key, schema)});
        }
    }
    return {
        queryParams,
        pageParams,
        attributeParams
    };
}